declare global {
  interface Window {
    appzi?: {
      openSurvey: (surveyId: string) => void;
    };
  }
}

export function useAppzi() {
  function openNpsModal() {
    if (window?.appzi) {
      window?.appzi.openSurvey('40a61a7a-d1ac-4e7b-9d21-4a6f5027e4c7');
    }
  }

  return { openNpsModal };
}
